import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Form, Validation, CheckboxField } from 'components/Forms'
import { BaseView } from 'views/BaseView'
import { I18n } from 'react-redux-i18n'
import { Grid } from '@material-ui/core'
import CardWorkingTime from './Components/CardWorkingTime'
import CardRound from './Components/CardRound'
import CardDayInWeek from './Components/CardDayInWeek'
import CardHoliday from './Components/CardHoliday'
import { Button } from '@material-ui/core';
import CardHourSetting from './Components/CardHourSetting'

const styles = theme => ({
    paper: {
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
    },
    submitBtn: {
        margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
    }
})

class Index extends BaseView {
    constructor(props) {
        super(props)
        this.validate = {
            required: Validation.required(I18n.t("Form.required"))
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(value){
        this.props.onSubmit({value: value})
    }

    render() {
        const { classes, data } = this.props
        let timeSettings = this.getData(data, "timeSetting", [])
        let timeSetting = this.getData(data, "timeSetting[0]", {})
        let holidaySetting = this.getData(data, "holidaySetting", [])
        return (
            <React.Fragment>
                <Form className={classes.form} onSubmit={(value) => this.onSubmit(value)}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} lg={6}>
                            <CardWorkingTime data={timeSettings} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <CardRound data={timeSetting} />
                            <CardDayInWeek data={timeSetting} />
                            <CardHourSetting data={timeSetting} />
                        </Grid>
                    </Grid>
                    <Button 
                        className = {classes.submitBtn} 
                        size="small" 
                        color="primary" 
                        type="submit" 
                        variant='contained'
                    >
                        {I18n.t("Button.submit")}
                    </Button>
                </Form>
                <Grid container spacing={32}>
                    <Grid item xs={12} lg={12}>
                        <CardHoliday
                            data={holidaySetting}
                            onSubmit={this.props.onUpdateHoliday}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Index)